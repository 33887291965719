<template>
  <div>
    <v-form>
      <p class="mb-3">
        Determine whether each of the statements below regarding hazards for chemicals in this
        experiment are true or false:
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px"></th>
            <th style="font-size: 15px"><b>True</b></th>
            <th style="font-size: 15px"><b>False</b></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Propionic anhydride is not an irritant</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.radio1" :disabled="!allowEditing">
                <v-radio key="true" value="true" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.radio1" :disabled="!allowEditing">
                <v-radio key="false" value="false" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>Phosphomolybdic acid fumes uncontrollably in air</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.radio2" :disabled="!allowEditing">
                <v-radio key="true" value="true" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.radio2" :disabled="!allowEditing">
                <v-radio key="false" value="false" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>Ethyl acetate is flammable</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.radio3" :disabled="!allowEditing">
                <v-radio key="true" value="true" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.radio3" :disabled="!allowEditing">
                <v-radio key="false" value="false" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>Toluene is a carcinogen</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.radio4" :disabled="!allowEditing">
                <v-radio key="true" value="true" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.radio4" :disabled="!allowEditing">
                <v-radio key="false" value="false" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>Triethylamine has to be used in a fume hood</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.radio5" :disabled="!allowEditing">
                <v-radio key="true" value="true" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.radio5" :disabled="!allowEditing">
                <v-radio key="false" value="false" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'ChemUCI_51LPL6_Q5',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        radio1: null,
        radio2: null,
        radio3: null,
        radio4: null,
        radio5: null,
      },
    };
  },
};
</script>
